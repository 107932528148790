<template>
  <div>
    <div>
      <v-data-table
        fixed-header
        dense
        :items="items"
        item-key="id"
        :headers="selection"
        :loading="Loading || load"
        loading-text="Chargement en cours ... Merci de patienter"
        :options.sync="options"
        multi-sort
        :server-items-length="total"
        :items-per-page="ipg"
        :footer-props="{
          'items-per-page-options': rowsPerPageItems,
          'disable-pagination': true,
        }"
        :single-select="!multiple"
        :show-select="multiple"
        @toggle-select-all="select_all"
        :key="dt"
        :group-by="groupby2"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <!-- Choix des colonnes  -->

            <v-menu
              v-model="showMenu"
              :close-on-content-click="false"
              transition="fab-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="indigo" v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list
                dense
                min-width="200"
                class="px-2 py-0 my-0 scroll"
                max-height="700"
              >
                <v-list-item
                  v-for="(item, i) in headers.filter(
                    (elm) =>
                      elm.hiden != true &&
                      elm.slot != 'col_btn1' &&
                      elm.slot != 'col_btn2'
                  )"
                  :key="i"
                >
                  <v-list-item-action>
                    <v-checkbox v-model="item.selected"></v-checkbox>
                  </v-list-item-action>

                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item>
                <v-divider v-if="list_options"></v-divider>
                <v-radio-group
                  v-model="expire"
                  @change="expire_change"
                  v-if="list_options"
                >
                  <v-list-item>
                    <v-list-item-action>
                      <v-radio :value="expire1"></v-radio>
                    </v-list-item-action>
                    <v-list-item-title>{{ exp1_label }} </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="isExpire">
                    <v-list-item-action>
                      <v-radio :value="expire2"></v-radio>
                    </v-list-item-action>
                    <v-list-item-title>{{ exp2_label }} </v-list-item-title>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-radio :value="expire3"></v-radio>
                    </v-list-item-action>
                    <v-list-item-title>{{ exp3_label }} </v-list-item-title>
                  </v-list-item>
                </v-radio-group>
              </v-list>
            </v-menu>

            <!-- Rechercher -->

            <v-text-field
              autocomplete="off"
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
            >
            </v-text-field>

            <!-- Periode -->

            <v-row>
              <v-col
                cols="12"
                :sm="byperiode ? 2 : 3"
                :md="byperiode ? 2 : 3"
                class="mt-6"
                v-if="byexercice"
              >
                <v-select
                  :items="exercicesWithAll"
                  v-model="exercice"
                  label="Exercice"
                  dense
                  @change="exercice_change"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                class="mt-6"
                v-if="byperiode && exercice != 'Tout'"
              >
                <datepicker
                  label="Du"
                  v-model="dd"
                  :edit="true"
                  :clearable="false"
                  :key="cs"
                ></datepicker>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                class="mt-6"
                v-if="byperiode && exercice != 'Tout'"
              >
                <datepicker
                  label="Au"
                  v-model="df"
                  :edit="true"
                  :clearable="false"
                  :key="cs"
                ></datepicker>
              </v-col>
              <v-col
                cols="12"
                sm="2"
                md="2"
                class="mt-6"
                v-if="byperiode && exercice != 'Tout'"
              >
                <v-btn
                  icon
                  color="indigo"
                  @click="periode_change"
                  :loading="btn1_load"
                  small
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn></v-col
              >
            </v-row>

            <!-- Extra buttons -->
            <v-btn
              text
              @click="click1"
              v-if="btn1.text != ''"
              :disabled="Loading || btn1_disable"
              :loading="btn1_load"
            >
              <v-icon left>{{ btn1.icon }}</v-icon>
              {{ btn1.text }}
            </v-btn>
            <v-btn
              text
              @click="click2"
              v-if="btn2.text != ''"
              :disabled="Loading"
              :loading="btn2_load"
            >
              <v-icon left>{{ btn2.icon }}</v-icon>
              {{ btn2.text }}
            </v-btn>
            <v-btn
              text
              @click="click3"
              v-if="btn3.text != ''"
              :disabled="Loading"
              :loading="btn3_load"
            >
              <v-icon left>{{ btn3.icon }}</v-icon>
              {{ btn3.text }}
            </v-btn>
            <v-btn
              text
              @click="click4"
              v-if="btn4.text != ''"
              :disabled="Loading"
              :loading="btn4_load"
            >
              <v-icon left>{{ btn4.icon }}</v-icon>
              {{ btn4.text }}
            </v-btn>
            <v-spacer></v-spacer>

            <!-- Groupping Menu -->

            <v-menu
              v-model="showMenu2"
              :close-on-content-click="true"
              transition="fab-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" v-if="groupby">
                  <v-icon>mdi-format-align-left</v-icon>
                </v-btn>
              </template>
              <v-list min-width="150">
                <v-list-item-group v-model="ItemGroupBy" color="primary">
                  <v-list-item
                    v-for="(item, i) in groupbylist"
                    :key="i"
                    @click="grouper_par(item)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-btn text v-if="groupby" @click="annuler_group">
              <v-icon>mdi-format-align-justify</v-icon>
            </v-btn>
            <!-- -------------------------------------- -->

            <template v-slot:activator>
              <v-btn v-model="fab" color="blue darken-2" icon>
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-icon v-else> mdi-account-circle </v-icon>
              </v-btn>
            </template>

            <v-tooltip bottom hidden>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="green"
                  ref="filterer"
                  icon
                  @click="OpenFilterForm"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="load"
                >
                  <v-icon> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtre</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="print_document"
                  v-if="header_print"
                  :disabled="items.length == 0"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-printer </v-icon>
                </v-btn>
              </template>
              <span>Imprimer</span>
            </v-tooltip>

            <upload-excel-component
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
              :tooltip="tooltip"
              v-if="imp_excel"
            />

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ref="excelexport"
                  icon
                  color="blue"
                  v-if="exp_excel && qSelectall"
                  @click="handleDownload"
                  v-bind="attrs"
                  v-on="on"
                  :loading="downloadLoading"
                  :disabled="load"
                >
                  <v-icon> mdi-file-excel </v-icon>
                </v-btn>
              </template>
              <span>Export To Excel</span>
            </v-tooltip>
            <!-- ------------------Duppliquer-------------------- -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="duplicate_item"
                  v-if="dup"
                  :disabled="!selecteditem.id"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-file-replace</v-icon>
                </v-btn>
              </template>
              <span>Duppliquer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="delRecord"
                  :disabled="del_disable || !selecteditem.id || load"
                  v-if="del"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon> mdi-minus </v-icon>
                </v-btn>
              </template>
              <span>Supprimer</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="itemnew"
                  v-if="Add"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="load"
                >
                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>Ajouter</span>
            </v-tooltip>

            <confirmdialog ref="confirm" />
          </v-toolbar>
          <v-alert
            v-if="unfilter"
            class="mt-2 mx-2"
            color="orange darken-1"
            dense
            elevation="4"
            icon="mdi-filter"
          >
            <v-row>
              <v-col class="grow"> {{ filter_text }}</v-col>
              <v-col class="shrink">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="filteroff" v-bind="attrs" v-on="on">
                      <v-icon> mdi-filter-off </v-icon>
                    </v-btn>
                  </template>
                  <span>Annuler Filtre</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-alert>

          <v-snackbar v-model="snackbar" top :color="snackbar_color">
            {{ snackbar_text }}
          </v-snackbar>
          <v-divider></v-divider>
        </template>

        <template v-slot:group.header="{ items, isOpen, toggle }">
          <th
            v-for="(header, columnIndex) in groupby2"
            :key="columnIndex"
            :colspan="selection.length"
          >
            <v-icon @click="toggle" v-if="columnIndex == 0"
              >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
            </v-icon>
            {{
              items[0][header]
                ? headers.find((elm) => elm.value == header).text +
                  ": " +
                  items[0][header]
                : ""
            }}
          </th>
        </template>

        <template v-slot:item="{ item, index }">
          <tr
            :class="selecteditem.id == item.id ? 'selected' : ''"
            @click="rowClicked(item, index)"
            @contextmenu="contextmenu($event, item)"
          >
            <td v-if="multiple">
              <v-simple-checkbox
                v-model="item.selected"
                style="margin: 0px; padding: 0px"
                :ripple="false"
                hide-details
                @input="checkbox_change"
              >
              </v-simple-checkbox>
            </td>
            <td
              valign="middle"
              v-for="(header, columnIndex) in selection"
              :key="columnIndex"
              style="text-align: left"
            >
              <div v-if="header.value == 'no'">
                {{ index + 1 + (options.page - 1) * 10 }}
              </div>
              <div v-else-if="header.hoover_list">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-if="!header.cur">
                      <span v-on="on">{{ item[header.value] }}</span>
                    </div>
                    <div class="text-right" v-if="header.cur">
                      <span v-on="on">{{
                        numberWithSpace(
                          item[header.value]
                            ? item[header.value].toFixed(
                                typeof header.dec == "number"
                                  ? header.dec
                                  : typeof header.dec == "string"
                                  ? item[header.dec]
                                  : 2
                              )
                            : "0.00"
                        )
                      }}</span>
                    </div>
                  </template>

                  <v-expand-transition
                    v-if="
                      header.hoover_list
                        ? item[header.hoover_list].length > 0
                        : false
                    "
                  >
                    <div>
                      <div
                        v-for="(item, i) in item[header.hoover_list]"
                        :key="i"
                      >
                        <span>
                          {{
                            item[header.hoover_name]
                              ? item[header.hoover_name] +
                                " : " +
                                (!header.cur
                                  ? item[header.hoover_value]
                                    ? item[header.hoover_value]
                                    : ""
                                  : numberWithSpace(
                                      item[header.hoover_value]
                                        ? item[header.hoover_value].toFixed(2)
                                        : "0.00"
                                    ) + " DA")
                              : "---------------------------------------"
                          }}
                        </span>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-tooltip>
              </div>
              <div v-else-if="header.slot == 'text_icon'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      dark
                      v-if="
                        item[header.column] != header.valeur &&
                        item[header.column] > 0
                      "
                    >
                      {{ header.icon ? header.icon : "mdi-pencil" }}
                    </v-icon>

                    <span v-else>
                      {{ item[header.value] }}
                    </span>
                  </template>
                  <v-expand-transition
                    v-if="
                      header.hoover_list
                        ? item[header.hoover_list]
                          ? item[header.hoover_list].length > 0
                          : false
                        : false
                    "
                  >
                    <div>
                      <div
                        v-for="(item, i) in item[header.hoover_list]"
                        :key="i"
                      >
                        {{
                          item[header.hoover_name] +
                          (item[header.hoover_value]
                            ? " : " + item[header.hoover_value]
                            : "")
                        }}
                      </div>
                    </div>
                  </v-expand-transition>
                </v-tooltip>
              </div>
              <div v-else-if="header.slot == 'icon'">
                <v-icon large>
                  {{ item[header.value] }}
                </v-icon>
              </div>
              <v-icon
                v-else-if="
                  header.value == 'actions' &&
                  item[header.column] == header.valeur &&
                  item[header.column1] == header.valeur1 &&
                  (del || $store.state.isadmin)
                "
                small
                class="mr-2"
                @click="delRecord(item, index)"
              >
                mdi-delete
              </v-icon>
              <div v-else-if="header.slot == 'rlink'">
                <a @click="routeto(item, index, header.router)">{{
                  item[header.value]
                }}</a>
              </div>
              <div
                v-else-if="
                  header.slot == 'col_btn1' &&
                  ((header.column &&
                    (header.operator
                      ? item[header.column] != header.valeur
                      : item[header.column] == header.valeur)) ||
                    header.column == null)
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="my-1"
                      icon
                      @click="col_btn1_click(item)"
                    >
                      <v-icon dark>
                        {{ header.icon ? header.icon : "mdi-pencil" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ header.tooltip ? header.tooltip : null }}</span>
                </v-tooltip>
              </div>
              <downloadcomp
                :item="item"
                :field="header.value"
                v-else-if="
                  header.slot == 'upload/download' &&
                  (header.column1
                    ? item[header.column1] == header.valeur1
                    : true)
                "
                :can_upload="
                  header.upload &&
                  (header.column2
                    ? item[header.column2] == header.valeur2
                    : true)
                "
                :upload_type="header.upload_type"
                :can_preview="header.preview"
                :can_download="header.download"
                :sh_upload="header.sh_upload"
                :sh_delete="header.sh_delete"
                :box_filter="header.box_filter ? header.box_filter : null"
                :can_delete="
                  header.delete &&
                  (header.column2
                    ? item[header.column2] == header.valeur2
                    : true)
                "
                :update_query="header.update_query"
                :var_name="header.var_name"
                @file_uploaded="file_uploaded"
                @file_deleted="file_deleted"
                :key="kcomp"
              >
              </downloadcomp>
              <div
                v-else-if="
                  header.slot == 'col_btn2' &&
                  ((header.column1 &&
                    (header.operator1
                      ? item[header.column1] != header.valeur1
                      : item[header.column1] == header.valeur1)) ||
                    header.column1 == null) &&
                  ((header.column2 &&
                    (header.operator2
                      ? item[header.column2] != header.valeur2
                      : item[header.column2] == header.valeur2)) ||
                    header.column2 == null)
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      :color="
                        item[header.color] ? item[header.color] : 'purple'
                      "
                      @click="col_btn2_click(item)"
                    >
                      <v-icon>
                        {{ header.icon ? header.icon : "mdi-pencil" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ header.tooltip ? header.tooltip : null }}</span>
                </v-tooltip>
              </div>
              <div v-else-if="header.slot == 'col_btn3'">
                <v-badge
                  :color="'purple'"
                  overlap
                  offset-y="n1"
                  v-if="item[header.value] > 0"
                >
                  <template v-slot:badge>
                    {{ item[header.value] }}
                  </template>
                  <v-icon @click="col_btn3_click(item)">
                    {{ header.icon ? header.icon : "mdi-pencil" }}
                  </v-icon>
                </v-badge>
                <v-icon v-else @click="col_btn3_click(item)">
                  {{ header.icon ? header.icon : "mdi-pencil" }}
                </v-icon>
              </div>
              <div
                v-else-if="
                  header.slot == 'col_btn4' &&
                  ((header.column1 &&
                    (header.operator1
                      ? item[header.column1] != header.valeur1
                      : item[header.column1] == header.valeur1)) ||
                    header.column1 == null) &&
                  ((header.column2 &&
                    (header.operator2
                      ? item[header.column2] != header.valeur2
                      : item[header.column2] == header.valeur2)) ||
                    header.column2 == null)
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      :color="
                        item[header.color] ? item[header.color] : 'purple'
                      "
                      @click="col_btn4_click(item)"
                    >
                      <v-icon>
                        {{ header.icon ? header.icon : "mdi-pencil" }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ header.tooltip ? header.tooltip : null }}</span>
                </v-tooltip>
              </div>
              <div
                v-else-if="header.slot == 'date'"
                :class="
                  header.cloture == true && item.cloture
                    ? 'clotured'
                    : header.expire == true && item.expire
                    ? 'expired'
                    : ''
                "
              >
                {{ datefr(item[header.value]) }}

                <v-icon v-if="!item[header.value] && header.icon" color="red">
                  {{ header.icon }}
                </v-icon>
              </div>
              <a
                @click="editItem(item, index, header.value)"
                v-else-if="header.slot == 'href' && !load"
              >
                {{ item[header.value] }}
              </a>
              <div align="right" v-else-if="header.slot == 'href2' && !load">
                <a @click="clickhrf(item, index, header.value)" class="cur4">
                  {{
                    numberWithSpace(
                      item[header.value]
                        ? item[header.value].toFixed(
                            typeof header.dec == "number"
                              ? header.dec
                              : typeof header.dec == "string"
                              ? item[header.dec]
                              : 2
                          )
                        : "0.00"
                    )
                  }}
                </a>
              </div>

              <div
                v-else-if="
                  header.slot == 'preview' && item[header.preview] == 1
                "
              >
                <a @click="clickhrf(item, index, header.value)">
                  {{ item[header.value] }}
                </a>
              </div>
              <div v-else-if="header.slot == 'yes/no'" class="cur4">
                <v-icon v-if="item[header.value]" color="green">
                  mdi-check</v-icon
                >
                <v-icon v-else color="red"> mdi-close</v-icon>
              </div>

              <div
                v-else-if="
                  header.slot == 'href2' && item[header.value] == 0 && !load
                "
                class="cur4"
              >
                0.00
              </div>
              <div align="right" v-else-if="header.slot == 'href3' && !load">
                <a @click="clickhrf(item, index, header.value)" class="cur4">
                  {{
                    numberWithSpace(
                      item[header.value]
                        ? item[header.value].toFixed(
                            typeof header.dec == "number"
                              ? header.dec
                              : typeof header.dec == "string"
                              ? item[header.dec]
                              : 2
                          )
                        : "0.00"
                    )
                  }}
                </a>
              </div>
              <div align="right" v-else-if="header.slot == 'href4'">
                <a
                  @click="clickhrf(item, index, header.value)"
                  class="cur4"
                  v-if="item[header.column] == header.valeur"
                >
                  {{
                    numberWithSpace(
                      item[header.value]
                        ? item[header.value].toFixed(
                            typeof header.dec == "number"
                              ? header.dec
                              : typeof header.dec == "string"
                              ? item[header.dec]
                              : 2
                          )
                        : "0.00"
                    )
                  }}
                </a>
                <span v-else>
                  {{
                    numberWithSpace(
                      item[header.value]
                        ? item[header.value].toFixed(
                            typeof header.dec == "number"
                              ? header.dec
                              : typeof header.dec == "string"
                              ? item[header.dec]
                              : 2
                          )
                        : "0.00"
                    )
                  }}</span
                >
              </div>
              <v-progress-linear
                v-else-if="header.slot == 'progress'"
                :value="item[header.value]"
                :color="
                  item[header.value] > 100 ? 'orange' : 'light-green darken-2'
                "
                height="25"
                rounded
              >
                <template v-slot:default>
                  <strong
                    >{{
                      item[header.value]
                        ? item[header.value].toFixed(2)
                        : "0.00"
                    }}%</strong
                  >
                </template>
              </v-progress-linear>
              <v-chip
                small
                :color="item[header.color] ? item[header.color] : 'green'"
                v-else-if="header.slot == 'chip'"
                >{{ item[header.value] }}</v-chip
              >
              <v-simple-checkbox
                disabled
                v-model="item[header.value]"
                v-else-if="header.slot == 'checkbox'"
                >{{
              }}</v-simple-checkbox>
              <div
                v-else-if="header.slot == 'cur'"
                align="right"
                :class="
                  header.cloture == true && item.cloture
                    ? 'clotured'
                    : header.expire == true && item.expire
                    ? 'expired'
                    : ''
                "
              >
                {{
                  numberWithSpace(
                    item[header.value]
                      ? item[header.value].toFixed(
                          typeof header.dec == "number"
                            ? header.dec
                            : typeof header.dec == "string"
                            ? item[header.dec]
                            : 2
                        )
                      : "0.00"
                  )
                }}
              </div>
              <div
                v-else-if="header.slot == 'cur22'"
                align="center"
                :class="
                  header.cloture == true && item.cloture
                    ? 'clotured'
                    : header.expire == true && item.expire
                    ? 'expired'
                    : ''
                "
              >
                {{
                  item[header.value]
                    ? numberWithSpace(
                        item[header.value].toFixed(
                          typeof header.dec == "number"
                            ? header.dec
                            : typeof header.dec == "string"
                            ? item[header.dec]
                            : 2
                        )
                      )
                    : "0"
                }}
              </div>
              <div v-else-if="header.value2">
                {{ item[header.value][header.value2] }}
              </div>
              <div
                v-else
                :class="
                  header.spec == true && item[header.column] > header.valeur
                    ? 'linked'
                    : header.cloture == true && item.cloture
                    ? 'clotured'
                    : header.expire == true && item.expire
                    ? 'expired'
                    : header.warned == true && item.warning
                    ? 'warned'
                    : ''
                "
                :align="header.align ? header.align : 'left'"
              >
                {{ item[header.value] }}
              </div>
            </td>
          </tr>
        </template>

        <template slot="body.append">
          <tr :key="ktot">
            <td v-if="multiple">
              <v-icon @click="multiple_click">{{ multiple_icon }}</v-icon>
            </td>
            <th
              v-for="(header, colIndex) in selection"
              :key="colIndex"
              style="text-align: right"
            >
              <span v-if="qTotal" class="cur3">
                {{
                  header.totlib
                    ? "Total"
                    : header.totcol || header.totcol == 0
                    ? numberWithSpace(
                        header.totcol
                          ? header.totcol.toFixed(
                              typeof header.dec == "number"
                                ? header.dec
                                : typeof header.dec == "string"
                                ? item[header.dec]
                                : 2
                            )
                          : "0.00"
                      )
                    : ""
                }}
              </span>
            </th>
          </tr>
        </template>

        <template v-slot:footer.prepend>
          <v-pagination
            v-model="page"
            :length="TotalPage"
            total-visible="7"
            @input="handlePageChange"
          ></v-pagination>
        </template>
      </v-data-table>
    </div>

    <formstd
      :item="editeditem"
      :items="items"
      :qCreate="qCreate"
      :qUpdate="qUpdate"
      :headers="headers"
      :title="title"
      :Get_suf="Get_suf"
      :key="keyform"
      :Update="Update"
      :showForm="!isClosed"
      @close="closeForm"
    >
    </formstd>
    <filteravc
      :showForm="!isFilterClosed"
      :columns_list="selection"
      :items="items"
      @close="closeFilterForm"
    >
    </filteravc>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import "jspdf-autotable";

import { addHeaders, addFooters } from "print/entete.js";
function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}
function debounce(fn, delay) {
  var timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    var args = arguments;
    var that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

export default {
  name: "listitemspage",
  props: {
    dup: { type: Boolean, default: false },
    title: String,
    headers: Array,
    qSelect: Object,
    qSelectall: Object,
    qDelete: Object,
    qCreate: Object,
    qUpdate: Object,
    qTotal: Object,
    Get_suf: String,
    Update: { type: Boolean, default: true },
    showedit: { type: Boolean, default: true },
    PageResponse: String,
    AllResponse: String,
    TotalResponse: String,
    field: String,
    field_list: Array,
    filename: String,
    sheetname: String,
    Add: { type: Boolean, default: true },
    del: { type: Boolean, default: true },
    byexercice: { type: Boolean, default: false },
    byperiode: { type: Boolean, default: false },
    header_print: Array,
    del_disable: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    multiple_icon: String,
    Kind: Number,
    Stock: Number,
    VarScope: Number,
    TypeScope: Number,
    search_elm: String,
    CatScope: Array,
    AttScope: Array,
    Service: Array,
    centrale: Array,
    Type: Array,
    WhereExt: Array,
    isExpire: { type: Boolean, default: true },
    list_options: { type: Boolean, default: true },
    selitem: Object,
    expire1: { type: Number, default: 1 },
    expire2: { type: Number, default: 2 },
    expire3: { type: Number, default: 3 },
    exp1_label: { type: String, default: "En cours" },
    exp2_label: { type: String, default: "Expire" },
    exp3_label: { type: String, default: "Tous" },
    tout: { type: Boolean, default: false },
    btn1_disable: { type: Boolean, default: false },
    btn1: {
      type: Object,
      default: () => ({
        text: "",
        icon: "",
      }),
    },
    btn2: {
      type: Object,
      default: () => ({
        text: "",
        icon: "",
      }),
    },
    btn3: {
      type: Object,
      default: () => ({
        text: "",
        icon: "",
      }),
    },
    btn4: {
      type: Object,
      default: () => ({
        text: "",
        icon: "",
      }),
    },
    btn1_load: { type: Boolean, default: false },
    btn2_load: { type: Boolean, default: false },
    btn3_load: { type: Boolean, default: false },
    btn4_load: { type: Boolean, default: false },
    exp_excel: { type: Boolean, default: true },
    imp_excel: { type: Boolean, default: false },
    load: { type: Boolean, default: false },
    periode: { type: Boolean, default: false },
    groupby: { type: Array, default: null },
    tooltip: String,
    ipg: { type: Number, default: 15 },
  },
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    formstd: () => import("../components/FormStd.vue"),
    UploadExcelComponent: () => import("../components/UploadExcel.vue"),
    datepicker: () => import("./DatePicker.vue"),
    filteravc: () => import("../components/FilterAvc.vue"),
    downloadcomp: () => import("../components/DownloadComp.vue"),
  },

  data: () => ({
    isFilterClosed: true,
    unfilter: false,
    filter_text: null,
    where: [],
    grouped: 1,
    ItemGroupBy: null,
    ValueGroupBy: null,
    exercice: null,
    dd: null,
    df: null,
    exercices: [],
    selected_list: [],
    downloadLoading: false,
    showMenu: false,
    showMenu2: false,
    fab: false,
    fs: 100,
    dt: 200,
    ktree: 300,
    cs: 4000,
    kcomp: 4521,
    ktot: 4789,
    expire: 1,
    options: {},
    selecteditem: {},
    editeditem: {},
    selectedRows: [],
    search: null,
    snackbar: false,
    snackbar_timeout: -1,
    snackbar_text: "",
    snackbar_color: "primary",
    rowsPerPageItems: [5, 10, 15, 20, 50, 100],
    items: [],
    TotalPage: 0,
    total: 0,
    page: 1,
    Loading: false,
    isClosed: true,
  }),

  computed: {
    exercicesWithAll() {
      let l = this.exercices;
      if (this.tout) l = ["Tout", ...this.exercices];
      return l;
    },
    groupbylist() {
      let l = [];
      if (this.groupby)
        this.groupby.forEach((element) => {
          l.push(this.headers[element]);
        });
      return l;
    },
    groupby2() {
      let l = [];
      if (this.ValueGroupBy) {
        l.push(this.ValueGroupBy);
      }
      return this.grouped == 0 ? l : [];
    },

    keyform() {
      return this.fs;
    },
    selection() {
      return this.headers.filter((item) => {
        if (
          item.selected === true &&
          item.hiden != true &&
          !this.groupby2.includes(item.value)
        ) {
          return item;
        }
      });
    },
  },
  watch: {
    search: debounce(function () {
      this.page = 1;
      this.expire = this.search ? this.expire3 : this.expire1;
      this.$emit("search", this.search);
      this.ShowMore("no-cache");
      this.totcolumns();
    }, 1000),
    options: {
      handler() {
        this.ShowMore("no-cache");
        this.totcolumns();
      },
      deep: true,
    },
    "$store.state.changed": {
      handler() {
        if (this.$store.state.changed) {
          this.ShowMore("no-cache");
          this.totcolumns();
        }
      },
      deep: true,
    },
    selitem: {
      handler() {
        if (this.selitem) {
          let i = this.items.findIndex((elm) => elm.id == this.selitem.id);
          if (i >= 0) {
            this.selecteditem = this.items[i];
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.kcomp++;
  },

  mounted() {
    this.expire = this.expire1;
    if (this.search != this.search_elm) this.search = this.search_elm;
    this.exercice = this.$store.state.exercice;
    let ex = this.exercice;
    while (ex >= 2023) {
      this.exercices.push(ex);
      ex--;
    }
    if (this.exercice) this.dd = this.exercice.toString() + "-01-01";
    this.df = this.$store.state.today;
    this.cs++;
  },

  methods: {
    file_uploaded(file, field) {
      this.$emit("file_uploaded", file, field);
    },
    file_deleted(field) {
      this.$emit("file_deleted", field);
    },
    contextmenu(event, item) {
      this.$emit("contextmenu", event, item);
    },
    OpenFilterForm() {
      this.$refs.filterer.$el.blur();
      this.isFilterClosed = false;
    },
    async closeFilterForm(f, t) {
      this.isFilterClosed = true;
      if (f) {
        this.filter_text = t;
        this.unfilter = true;
        this.where = f;
        await this.ShowMore("no-cache");
        await this.totcolumns();
        this.dt++;
      } else this.where = [];
    },
    async grouper_par(item) {
      this.ValueGroupBy = item.value;
      this.options.sortBy = [];
      this.options.sortDesc = [];
      this.options.sortBy.push(item.value);
      this.options.sortDesc.push(true);
      await this.ShowMore();
      this.grouped = 0;
    },
    annuler_group() {
      this.options.sortBy = [];
      this.options.sortDesc = [];
      this.grouped = 1;
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    sumSelect(key) {
      return this.selected_list.reduce((a, b) => a + (b[key] || 0), 0);
    },

    handleSuccess({ results, header }) {
      let items = {
        headers: header,
        results: results,
        headersequal: arraysEqual(
          this.selection.map((elm) => elm.text),
          header
        ),
      };
      this.$emit("excel_import", items);
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 4;
      if (isLt1M) {
        return true;
      }
      this.snackbar_text = "Please do not upload files larger than 4m in size.";
      this.snackbar_color = "warning";
      this.snackbar = true;

      return false;
    },
    datefr(date) {
      let d;
      if (date) {
        var result = new Date(date);
        d = result.toLocaleDateString("fr-FR", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      }
      return d;
    },
    periode_change() {
      this.$emit("periode_change", this.dd, this.df);
      this.ShowMore("no-cache");
      this.totcolumns();
    },
    exercice_change(value) {
      if (value === "Tout") {
        this.dd = null;
        this.df = null;
      } else {
        this.dd = this.exercice.toString() + "-01-01";
        this.df = this.exercice.toString() + "-12-31";
        this.cs++;
      }
      this.$emit("exercice_change", this.exercice);
      this.ShowMore("no-cache");
      this.totcolumns();
    },
    async print_document() {
      let allitems = await this.getallitems();

      let head = [];
      let columns = [];
      let items = [];
      let foot = [];
      let item;
      //data formating

      for (let index = 0; index < allitems.length; index++) {
        item = {};
        this.header_print.forEach((elm) => {
          if (elm.value == "no") {
            item[elm.value] = index + 1;
          }
          if (elm.slot == "cur22") {
            item[elm.value] = this.numberWithSpace(
              parseFloat(allitems[index][elm.value]).toFixed(2)
            );
          } else if (elm.slot == "cur" && allitems[index][elm.value]) {
            item[elm.value] = this.numberWithSpace(
              parseFloat(allitems[index][elm.value]).toFixed(2)
            );
          } else if (allitems[index][elm.value])
            item[elm.value] = allitems[index][elm.value];
        });
        items.push(item);
      }

      let columnStyles = {};
      var doc = new jsPDF(this.orientation);
      item = {};
      this.header_print.forEach((element) => {
        head.push({
          content: element.text,
          styles: {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          },
        });

        if (element.totlib) {
          item[element.value] = "Total";
        } else if (element.total != null) {
          let v = allitems.reduce((a, b) => a + (b[element.total] || 0), 0);
          if (typeof v == "number")
            item[element.value] = this.numberWithSpace(v.toFixed(2));
          else
            item[element.value] = this.numberWithSpace(
              element.total.toFixed(2)
            );
        }

        columns.push({ dataKey: element.value });
        columnStyles[element.value] = {
          halign:
            element.align == "end"
              ? "right"
              : element.align == "center"
              ? "center"
              : "left",
          cellWidth: element.slot == "cur" ? 28 : "auto",
          fontSize: 9,
        };
      });

      if (this.qTotal) foot.push(item);

      doc.autoTable({
        margin: { top: 50 },
        headStyles: {
          valign: "middle",
          fillColor: [128, 128, 128],
          fontSize: 9,
        },
        columnStyles: columnStyles,
        head: [head],
        body: items,
        columns: columns,
        foot: foot,
        footStyles: {
          halign: "right",
          fillColor: [128, 128, 128],
          fontSize: 9,
        },
        showHead: "everyPage",
        showFoot: "lastPage",
      });
      addHeaders(doc, this.$store);
      addFooters(doc, this.$store);
      doc.setProperties({ title: this.title });
      doc.output("pdfobjectnewwindow");
    },
    async handleDownload() {
      this.downloadLoading = true;
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;
      let allitems = await this.getallitems();

      // const headers = {};

      // this.selection.forEach((element) => {
      //   headers[element.value] = element.text;
      // });

      // let data = [];

      // allitems.forEach((element) => {
      //   let obj = {};

      //   this.selection.forEach((element2) => {
      //     obj[element2.value] = element[element2.value];
      //   });
      //   data.push(obj);
      // });

      //exportToXLSX(data, this.filename + ".xlsx", headers, [7, 8]);
      import("@/utils/export").then((excel) => {
        const tHeader = this.selection.map((elm) => elm.text);
        const filterVal = this.selection.map((elm) => elm.value);
        const list = allitems;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.filename,
          autoWidth: true,
          bookType: "xlsx",
        });
      });
      this.downloadLoading = false;
      this.snackbar_text = "Chargement terminé";
      this.snackbar_color = "success";
      this.snackbar = true;
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
    sumCol(key) {
      // sum data in give key (property)
      return this.list.reduce((a, b) => a + (b[key] || 0), 0);
    },
    clickhrf(item, index, col) {
      item.index = index;
      item.col = col;
      this.editeditem = item;
      this.editeditem.index = index;
      this.editeditem.col = col;
      this.$emit("hrf", this.editeditem);
    },
    multiple_click() {
      this.$emit("multiple_click", this.selected_list);
    },
    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    closeForm() {
      this.editeditem = {};
      this.isClosed = true;
    },
    col_btn1_click(item) {
      this.$emit("col_btn1_click", item);
    },
    col_btn2_click(item) {
      this.$emit("col_btn2_click", item);
    },
    col_btn3_click(item) {
      this.$emit("col_btn3_click", item);
    },
    col_btn4_click(item) {
      this.$emit("col_btn4_click", item);
    },
    getwhere() {
      var Wherelist = [];
      if (this.search) {
        var i;
        var x = "%";
        x = x.concat(this.search, "%");
        for (i in this.headers) {
          if (this.headers[i].selected && this.headers[i].enum) {
            Wherelist.push({
              column: this.headers[i].enum,
              operator: "LIKE",
              value: x,
            });
          }
        }
      }
      let where = [];
      if (Wherelist.length == 0) {
        if (this.where.length > 0 && this.WhereExt)
          where = { AND: [{ AND: this.where }, { AND: this.WhereExt }] };
        else if (this.where.length > 0) where = { AND: this.where };
        else if (this.WhereExt) where = { AND: this.WhereExt };
      }
      if (Wherelist.length > 0) {
        if (this.where.length > 0 && this.WhereExt)
          where = {
            AND: [
              { OR: Wherelist },
              { AND: this.where },
              { AND: this.WhereExt },
            ],
          };
        else if (this.where.length > 0)
          where = { AND: [{ OR: Wherelist }, { AND: this.where }] };
        else if (this.WhereExt)
          where = { AND: [{ OR: Wherelist }, { AND: this.WhereExt }] };
        else where = { OR: Wherelist };
      }
      return where;
    },
    async getallitems() {
      var where = null;

      where = this.getwhere();
      let field = this.field;
      let order = "DESC";
      let orderby = [];
      const { sortBy, sortDesc } = this.options;
      for (let index = 0; index < sortBy.length; index++) {
        const element = sortBy[index];
        field =
          this.headers[this.headers.findIndex((h) => h.value === element)].enum;
        sortDesc[index] ? (order = "DESC") : (order = "ASC");
        orderby.push({ column: field, order: order });
      }
      if (orderby.length == 0) {
        if (this.field_list) {
          this.field_list.forEach((element) => {
            orderby.push({ column: element, order: order });
          });
        } else orderby.push({ column: field, order: order });
      }
      this.snackbar = true;
      this.snackbar_text = "Chargement données";
      this.snackbar_timeout = -1;
      let response = [];
      await this.$apollo
        .query({
          query: this.qSelectall,
          variables: {
            where: where,
            orderby: orderby,
            Kind: this.Kind,
            Stock: this.Stock,
            VarScope: this.VarScope,
            TypeScope: this.TypeScope,
            date_debut: this.dd,
            date_fin: this.df,
            CatScope: this.CatScope,
            AttScope: this.AttScope,
            Service: this.Service,
            Type: this.Type,
            expire: this.expire,
            centrale: this.centrale,
          },
        })
        .then((data) => {
          //this.items = data["data"][this.AllResponse];
          this.snackbar = false;
          response = data["data"][this.AllResponse];
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });

      return response;
    },
    async totcolumns() {
      let where = this.getwhere();
      if (this.qTotal) {
        await this.$apollo
          .query({
            query: this.qTotal,
            variables: {
              where: where,
              Kind: this.Kind,
              Stock: this.Stock,
              VarScope: this.VarScope,
              TypeScope: this.TypeScope,
              date_debut: this.dd,
              date_fin: this.df,
              CatScope: this.CatScope,
              AttScope: this.AttScope,
              Service: this.Service,
              Type: this.Type,
              expire: this.expire,
              centrale: this.centrale,
            },
            fetchPolicy: "no-cache",
          })
          .then((data) => {
            this.headers.forEach((element) => {
              if (element.result) {
                element.totcol =
                  data.data[this.TotalResponse][0][element.result];
              }
            });
            this.ktot++;
          })
          .catch((error) => {
            this.snackbar_text = error.message;
            this.snackbar_color = "error";
            this.snackbar = true;
          });
      }
    },
    handlePageChange(value) {
      this.selecteditem = "";
      this.page = value;
      this.options.page = value;
    },
    select_all(props) {
      if (
        props.items.length === this.items.filter((elm) => elm.selected).length
      ) {
        props.items.forEach((element) => {
          element.selected = false;
        });
      } else {
        props.items.forEach((element) => {
          element.selected = true;
        });
      }
      this.selected_list = props.items.filter((elm) => elm.selected);
      this.$emit("selected_rows", this.selected_list);
    },
    expire_change() {
      this.ShowMore();
      this.totcolumns();
    },
    async ShowMore(f) {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.selected_list = [];
        this.showMenu = false;
        let field = this.field;
        let order = "DESC";
        let orderby = [];
        const { sortBy, sortDesc } = this.options;
        for (let index = 0; index < sortBy.length; index++) {
          const element = sortBy[index];
          field =
            this.headers[this.headers.findIndex((h) => h.value === element)]
              .enum;
          sortDesc[index] ? (order = "DESC") : (order = "ASC");
          orderby.push({ column: field, order: order });
        }

        if (orderby.length == 0) {
          if (this.field_list) {
            this.field_list.forEach((element) => {
              orderby.push({ column: element, order: order });
            });
          } else orderby.push({ column: field, order: order });
        }
        let page = this.page;
        if (this.page - this.total / this.options.itemsPerPage > 1) {
          page = 1;
        }
        var where = null;
        where = this.getwhere();

        this.Loading = true;
        this.$apollo
          .query({
            query: this.qSelect,
            variables: {
              page: page,
              first: this.options.itemsPerPage,
              orderby: orderby,
              where: where,
              Kind: this.Kind,
              Stock: this.Stock,
              VarScope: this.VarScope,
              TypeScope: this.TypeScope,
              date_debut: this.dd,
              date_fin: this.df,
              CatScope: this.CatScope,
              AttScope: this.AttScope,
              Service: this.Service,
              Type: this.Type,
              expire: this.expire,
              centrale: this.centrale,
            },
            fetchPolicy: f,
          })
          .then((data) => {
            this.items = data["data"][this.PageResponse]["data"];
            if (this.selitem) {
              let i = this.items.findIndex((elm) => elm.id == this.selitem.id);
              if (i >= 0) {
                this.selecteditem = this.items[i];
                this.$emit("rowselect", this.selecteditem);
              } else this.$emit("rowselect", {});
            }
            this.TotalPage =
              data["data"][this.PageResponse]["paginatorInfo"]["lastPage"];
            this.total =
              data["data"][this.PageResponse]["paginatorInfo"]["total"];

            this.$store.dispatch("Changed", false);
            this.Loading = false;
          })
          .catch((error) => {
            this.snackbar_text = error.message;
            this.snackbar_color = "error";
            this.snackbar = true;
            this.Loading = false;
          });
        let items = this.items;
        let total = this.total;
        setTimeout(() => {
          resolve({
            items,
            total,
          });
        }, 1000);
      });
    },

    rowClicked(row, index) {
      row.index = index;
      this.selecteditem = row;
      this.selected_list = this.items.filter((elm) => elm.selected);
      this.$emit("selected_rows", this.selected_list);
      this.$emit("rowselect", this.selecteditem);
    },
    checkbox_change() {
      this.selected_list = this.items.filter((elm) => elm.selected);
      this.$emit("selected_rows", this.selected_list);
    },
    async filteroff() {
      this.unfilter = false;
      this.where = [];
      await this.ShowMore();
      await this.totcolumns();
      this.dt++;
    },
    click1() {
      this.$emit("click1");
    },
    click2() {
      this.$emit("click2");
    },
    click3() {
      this.$emit("click3");
    },
    click4() {
      this.$emit("click4");
    },
    itemnew() {
      this.editeditem = { id: -1 };
      this.fs++;
      if (this.showedit) this.isClosed = false;
      else this.$emit("open", this.editeditem);
    },
    startDownload() {
      this.snackbar_text = "Chargement données";
      this.snackbar_color = "primary";
      this.snackbar = true;
    },
    finishDownload() {
      this.snackbar_text = "Chargement terminé";
      this.snackbar_color = "success";
    },

    editItem(item, index, col) {
      item.index = index;
      item.col = col;
      this.fs++;
      this.editeditem = item;
      if (this.showedit) this.isClosed = false;
      else this.$emit("open", this.editeditem);
    },
    routeto(item, index, to) {
      item.index = index;
      this.$router.push({ name: to, params: { item: item } });
    },
    async delRecord() {
      if (this.qDelete) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cet element?"
          )
        ) {
          this.items.splice(this.selecteditem.index, 1);
          this.$apollo
            .mutate({
              mutation: this.qDelete,
              variables: {
                id: this.selecteditem.id,
              },
            })
            .then(() => {
              this.$store.dispatch("Changed", true);
              this.$emit("delete", this.selecteditem);
            })
            .catch((error) => {
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
      } else this.$emit("delete", this.selecteditem);
    },

    duplicate_item() {
      this.$emit("duplicate", this.selecteditem);
    },
  },
};
</script>

<style lang="scss">
.v-pagination__item,
.v-pagination__navigation {
  box-shadow: none !important;
}

@import "../assets/css/default.scss";
</style>
